import React from "react";
import "./About-pages.scss";
import aboutImg from "../../Assets/Fasilitas/ft-12.webp";

const AboutPages = () => {
  const waOpen = () => {
    window.open(
      `https://api.whatsapp.com/send/?phone=6285716429078&text=Halo+Wely,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Citra%20Garden%20Bintaro?%20https://www.marketingciputrabintaro.com/&type=phone_number&app_absent=0`,
      "_blank"
    );
  };
  return (
    <div className="aboutcont" id="about">
      <div className="center">
        <img className="img" src={aboutImg} alt="About Us" />
        <div className="abouttile">About Us</div>
        <div className="aboutcontent">
          <div className="title">CITRAGARDEN BINTARO</div>
          <div className="subtitle">
            <div className="text">
              Citra Garden Bintaro adalah sebuah kompleks perumahan yang
              terletak di jantung Kota Tangerang Selatan, tepatnya di wilayah
              Bintaro. Citra Garden Bintaro dikembangkan oleh Ciputra dengan
              konsep hunian eco culture yang modern dan lingkungan hijau dengan
              pepohonan dan tanaman hijau lainnya yang membuat lingkungan
              menjadi lebih bersih, sejuk, dan nyaman. Rumah di Citra Garden
              Bintaro didesain dengan gaya European Modern.
            </div>
            <button onClick={waOpen} className="wabutton">
              Learn More
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPages;
